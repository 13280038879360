import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { getImage, ImageDataLike, GatsbyImage } from 'gatsby-plugin-image'

import { Container, Layout } from '../../components/Layout'
import {
  BodyText1,
  BodyText2,
  Heading2,
  Heading4,
} from '../../components/Heading'
import { Divider } from '../../components/Divider'
import Modal from '../../components/Modal'
import { resolveServerImg } from '../../utils'
interface Member {
  name: string
  position: string
  avatar: ServerImageData
  description: Paragraph[]
  type: 'employee' | 'partner'
  index: number
}

interface Team {
  title: string
  head: SectionCard
  slogan: string
  members: APIListResponse<Member>
}

import './index.scss'
import { Button } from '../../components/Button'
import { useTranslation } from '../../i18n'

interface CardProps {
  avatar: ImageDataLike | null
  name: string
  title: string
  content: string | React.ReactNode
  collapse?: boolean
}

export const Card: React.FC<CardProps> = ({ avatar, name, title, content }) => {
  const [open, setOpen] = useState(false)
  const image = avatar ? getImage(avatar) : null
  const detail = (
    <>
      <div className="name">
        <Heading4>{name}</Heading4>
        <BodyText1>{title}</BodyText1>
      </div>
      <Divider></Divider>
      <div className="words">{content}</div>
    </>
  )
  const { t } = useTranslation({})

  return (
    <div className="card-item">
      <div className="avatar">
        {image && <GatsbyImage image={image} alt={name}></GatsbyImage>}
      </div>
      <div className="card-content">
        {detail}
        <Button border className="open" onClick={() => setOpen(true)}>
          {t('viewMore')}
        </Button>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        {detail}
      </Modal>
    </div>
  )
}

export const TeamPage: React.FC<PageData<{ team: APIResponse<Team> }>> = ({
  pageContext,
}) => {
  const {
    serverData: { team },
  } = pageContext

  const formatMember = (member: ResponseData<Member>) => {
    const {
      attributes: { name, position, description, avatar },
    } = member
    return {
      name: name,
      title: position,
      content: description.map((des) => des.description),
      avatar: resolveServerImg(avatar),
    }
  }

  const sortedMembers = team.data.attributes.members.data.sort((a, b) => {
    return a.attributes.index - b.attributes.index
  })

  const partners: CardProps[] = sortedMembers
    .filter((m) => !!m && m.attributes.type === 'partner')
    .map(formatMember)

  const members: CardProps[] = sortedMembers
    .filter((m) => !!m && m.attributes.type === 'employee')
    .map(formatMember)

  return (
    <Layout className="team-page">
      <Helmet>
        <title>{team.data.attributes.title}</title>
      </Helmet>
      <Container className="head">
        <Heading2>{team.data.attributes.head.title}</Heading2>
        <BodyText1>{team.data.attributes.head.description}</BodyText1>
      </Container>

      <Container className="slogan">
        <Heading4>{team.data.attributes.slogan}</Heading4>
      </Container>
      <Container className="partners">
        {partners.map((partner) => {
          let contents = partner.content
          if (Array.isArray(partner.content)) {
            contents = partner.content.map((co, index) => {
              return (
                <React.Fragment key={index.toString()}>
                  <span className="body-text1">{co}</span>
                  <br />
                  <br />
                </React.Fragment>
              )
            })
          }
          return <Card {...partner} content={contents} key={partner.name} />
        })}
      </Container>
      <Container className="members">
        {members.map((members) => {
          let contents = members.content
          if (Array.isArray(members.content)) {
            contents = members.content.map((iv, index) => {
              return <BodyText2 key={index.toString()}>{iv}</BodyText2>
            })
          }
          return <Card {...members} content={contents} key={members.name} />
        })}
      </Container>
    </Layout>
  )
}

export default TeamPage
